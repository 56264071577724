<template>
  <div :key="slide.title" class="MobileItem">
    <img width="80" height="80" v-bind:src="slide.icon" v-bind:alt="slide.title">
    <div class="MITitle">{{ $t(`aboutUsBlock.grid.${slide.id}.title`) }}</div>
    <div v-if="slide.list && $i18n.locale === 'en-US'">
      <ul v-for="(subItem, i) in slide.list" v-bind:key="i">
        <li class="MILiSubtitle">{{ subItem }}</li>
      </ul>
    </div>
    <div v-if="$i18n.locale === 'ru-RU' && slide.ruList">
      <ul v-for="(subItem, i) in slide.ruList" v-bind:key="i">
        <li class="MILiSubtitle">{{ subItem }}</li>
      </ul>
    </div>
    <div v-else class="MISubtitle">{{ $t(`aboutUsBlock.grid.${slide.id}.text`) }}</div>
  </div>
</template>

<script>
import "@/assets/styles/global-styles.css"


export default {
  name: 'MobileSliderItem',
  props: {
    slide: {
      required: true
    }
  },
  data() {
    return {

    }
  },
  methods: {
    join() {
      this.$router.push('/join-us')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.MobileItem {
  width: 90%;
  height: 250px;
  display: flex;
  align-items: center;
  text-align: justify-all;
  flex-direction: column;
}

.MITitle {
  margin-top: 24px;
  font-weight: 600;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.87);
}

.MISubtitle {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.54);
}

.MILiSubtitle {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.54);
  text-align: start;
}
</style>
