<template>
  <div class="CaseWrapper" v-bind:class="reversed?'': 'Reverse'">
    <div class="MainInfo" v-bind:class="positionColor?'GreenBlock':'BlackBlock'">
      <div class="TopLine" v-bind:class="positionColor?'':'Green'" />
      <span class="Title" v-bind:class="positionColor?'':'GreenTitle'">{{ $t(`expertise.grid.${this.case.id}.title`)
      }}</span>
      <div class="Subtitle">{{ $t(`expertise.grid.${this.case.id}.subtitle`) }}</div>
    </div>
    <div v-bind:data-aos="reversed?'fade-left':'fade-right'" class="DescriptionInfo"
      v-bind:class="positionColor?'GreenD':'WhiteD '">
      <div v-if="$i18n.locale === 'ru-RU' && this.case.ruListHeader" class="DescriptionItem"
        v-bind:class="positionColor? 'GreenD':'WhiteD'">
        {{ $t(`expertise.grid.${this.case.id}.listHeader`) }}
      </div>
      <div v-if="$i18n.locale === 'en-US' && this.case.engListHeader" class="DescriptionItem"
        v-bind:class="positionColor? 'GreenD':'WhiteD'">
        {{ $t(`expertise.grid.${this.case.id}.listHeader`) }}
      </div>
      <ul  class="listWrapper" @click="test">
          <li v-for="(item, i) in $i18n.locale === 'ru-RU' ? caseInfo.ruList : caseInfo.engList" v-bind:key="i">
            {{ $t(`expertise.grid.${caseInfo.id}.${item}`) }}
          </li>
      </ul>
      <div v-if="caseInfo.ruDescriptions || caseInfo.descriptions">
        <div v-for="(item, i) in $i18n.locale === 'ru-RU'? caseInfo.ruDescriptions : caseInfo.descriptions" :key="i" class="DescriptionItem"
          v-bind:class="positionColor? 'GreenD':'WhiteD'">{{
          $tc(`expertise.grid.${caseInfo.id}.descriptions[${item}]`) }}
        </div>
      </div>
      <div v-bind:class="reversed?
      positionColor? 'TriangleRight' : 'TriangleRight WhiteTriangleRight' :
      positionColor? 'TriangleLeft' : 'TriangleLeft WhiteTriangleLeft'"></div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    case: {
      required: true,
    },
  },
  name: 'Case',
  data() {
    return {
      myRef: this,
      caseInfo: this.case,
      reversed: this.case.row === "odd",
      positionColor: this.case.id % 2
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.CaseWrapper {
  width: 604px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
}

@media (max-width:1025px) {
  .CaseWrapper {
    width: 100%;
  }
}


.Reverse {
  flex-direction: row-reverse;
}

.MainInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 34px 28px 34px 22px;
  width: 50%;
  height: 100%;
}

.GreenBlock {
  background: #8BBC00;
}

.BlackBlock {
  background: #434242;
}

.TopLine {
  width: 40px;
  height: 1px;
  margin-bottom: 24px;
  border-bottom: 2px solid #FFFFFF;
}

.Green {
  border-bottom: 2px solid #8BBC00;
}

.Title {
  font-size: 24px;
  color: #FFFFFF;
  margin-bottom: 24px;
}

.GreenTitle {
  color: #8BBC00
}

.Subtitle {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
}

.DescriptionInfo {
  position: relative;
  display: flex;
  text-align: left;
  flex-direction: column;
  padding: 33px 28px 33px 22px;
  width: 50%;
  border: 1px solid rgba(0, 0, 0, 0.14);
  height: 100%;
}

.DescriptionItem {
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.15px;
  padding: 4px;
}

.GreenD {
  color: #FFFFFF;
  background: #759715;
}

.WhiteD {
  background: #FFFFFF;
  color: rgba(0, 0, 0, 0.87);
}

.TriangleLeft {
  position: absolute;
  top: 40%;
  left: 100%;
  border-top: 25px solid transparent;
  border-left: 20px solid #759715;
  border-bottom: 25px solid transparent;
}

.TriangleRight {
  position: absolute;
  top: 40%;
  left: -19px;
  border-top: 25px solid transparent;
  border-right: 20px solid #759715;
  border-bottom: 25px solid transparent;
}

.WhiteTriangleLeft {
  border-left: 20px solid #FFFFFF;
}

.WhiteTriangleRight {
  border-right: 20px solid #FFFFFF;
}

@media (max-width: 670px) {
  .CaseWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 524px) {
  .DescriptionInfo {
    display: none;
  }

  .MainInfo {
    width: 90%;
    height: 135px;
  }
}

.listWrapper {
  margin-left: 18px;
}

.listWrapper li {
  margin: 5px;
}
</style>
