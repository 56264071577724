<template>
  <div class="ServicesWrapper">
    <div class="TaxPlatformHead">
      <div class="TPTitle"> {{ $t(`servicesBlock.title.mainText`) }}</div>
      <div class="TPSubtitle">
        {{ $t(`servicesBlock.title.whiteFirst`) }}
       <span class="TPSubtitle TPSubtitleGreen"> {{ $t(`servicesBlock.title.greenFirst`) }}</span>
       {{ $t(`servicesBlock.title.whiteSecond`) }}
        <span class="TPSubtitle TPSubtitleGreen"> {{ $t(`servicesBlock.title.greenSecond`) }}</span>
      </div>
    </div>
    <ServiceSlider :current-slide="currentSlide-1"/>
    <div class="ServiceSliderButtons">
      <div class="ContainedButtonBorder">
        <button v-bind:disabled="currentSlide===1" @click="slideBackward" class="GreenContainedButton">{{ $t(`servicesBlock.pagination.back`) }}</button>
      </div>
      <div class="ContainedButtonBorder">
        <button  @click="slideForward" class="GreenContainedButton">{{ $t(`servicesBlock.pagination.forward`) }}</button>
      </div>
      <div>{{ this.currentSlide }} {{ $t(`servicesBlock.pagination.numbers`) }} {{this.slidesAmount}}</div>
    </div>
  </div>
</template>

<script>
import "@/assets/styles/global-styles.css"
import ServiceSlider from "@/components/services_block/ServiceSlider";
import {slidesData} from "./slides-data"

export default {
  name: 'ServicesBlock',
  components: {ServiceSlider},
  data(){
    return{
      currentSlide: 1,
      slidesAmount: slidesData.length
    }
  },
  methods:{
    slideForward(){
      if(this.currentSlide+1>this.slidesAmount){
        this.currentSlide=1
      }else {
        this.currentSlide++
      }
    },
    slideBackward(){
        this.currentSlide--
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ServicesWrapper {
  padding-top: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-image: url("../../assets/images/servicesBG.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 60px;
}
.TaxPlatformHead{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 200px;
  margin-bottom: 40px;
  background-image: url("../../assets/images/serviceHeader.svg");
  background-size: 50vw;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
}

.TPTitle{
  font-size: 36px;
  font-weight: 600;
  color: #FFFFFF;
  margin-bottom: 16px;
}
.TPSubtitle{
  color: rgba(255, 255, 255, 0.64);
}
.TPSubtitleGreen{
  color: #8BBC00
}
.ServiceSliderButtons {
  color: #ffffff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 285px;
}
@media(max-width: 650px){
  .ServicesWrapper{
    padding-top: 0;
  }
  .TaxPlatformHead{
    background-size: 100vw;
  }
}
</style>
