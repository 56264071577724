<template>
  <div class="MainSliderWrapper">
    <transition name="component-fade" mode="out-in">
      <AboutUsSlide v-if="currentSlide===0"/>
<!--      <IkassaSlide v-if="currentSlide===1"/>-->
    </transition>
  </div>
</template>

<script>
import AboutUsSlide from "@/components/top_block/slides/AboutUsSlide";
// import IkassaSlide from "@/components/top_block/slides/IkassaSlide";

export default {
  name: 'MainSlider',
  components: {
    AboutUsSlide,
  },
  mounted() {
    //Commented code is future logic for main slider
    // setInterval(() => {
    //   this.slide()
    // }, 5000);
  },
  data() {
    return {
      currentSlide: 0
    }
  },
  methods: {
    //Commented code is future logic for main slider
    // slide() {
    //   this.currentSlide > 2 ? this.currentSlide = 0 : this.currentSlide++
    // }
  }
}
</script>

<style scoped>
.MainSliderWrapper {
}

.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}

.component-fade-enter, .component-fade-leave-to {
  opacity: 0;
}
</style>
