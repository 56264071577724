import Vue from 'vue'
import App from './App.vue'
import Lottie from 'vue-lottie';
import router from './router'
import AOS from 'aos'
import 'aos/dist/aos.css'
import VueRouter from 'vue-router'
import i18n from './i18n'

Vue.config.productionTip = false

Vue.use(VueRouter, Lottie)

new Vue({
  i18n,
  router,
  created() {
    AOS.init({
      once: true
    })
  },
  render: h => h(App),
}).$mount('#app')
