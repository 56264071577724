<template>
  <div class="TaxPlatformContentWrapper">
    <div v-for="(curveItem, i) in this.curveItems" v-bind:key="i">
      <div :class="'TaxElement' + curveItem.style">
        <div class="shadow-magic">
          <div class="Icon">{{i+1}}</div>
          <div class="ItemColumn">
            <h3 class="CITitle">{{ $t(`taxPlatform.grid.${curveItem.key}.title`) }}</h3>
            <div class="CISubtitle">{{ $t(`taxPlatform.grid.${curveItem.key}.subtitle`) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/styles/global-styles.css"

export default {
  name: ' ',
  data() {
    return {
      curveItems: [
        {
          key:'data',
          class: "CurveItem first",
          style: " tax1"
        },
        {
          key:'storage',
          class: "CurveItem second",
          style: " tax2"
        },
        {
          key:'validation',
          class: "CurveItem third",
          style: " tax3"
        },
        {
          key:'analytics',
          class: "CurveItem fourth",
          style: " tax4"
        }]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.TaxPlatformContentWrapper {
  background-color: black;
  display: flex;
  width: 100vw;
  height: 700px;
}

@media(max-width: 1440px) {
  .TaxPlatformContentWrapper {
    height: 500px;
  }
}

.Icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 500;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: #ffffff;
}

.TaxElement {
  width: 25vw;
  height: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.tax1 {
  background-image: url("../../assets/images/tax1.jpg");
}

.tax2 {
  background-image: url("../../assets/images/tax2.jpg");
}

.tax3 {
  background-image: url("../../assets/images/tax3.jpg");
}

.tax4 {
  background-image: url("../../assets/images/tax4.jpg");
}

//------
.shadow-magic {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.shadow-magic:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: -1px;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.9) 100%);
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.shadow-magic:hover, .shadow-magic:focus, .shadow-magic:active {
  color: white;
}

.CITitle {
  font-size: 24px;
  color: #FFFFFF;
  font-weight: 500;
  padding: 16px 24px 4px 24px;
  min-height: 28px;
}

.CISubtitle {
  padding: 4px 24px;
  color: rgba(255, 255, 255, 0.64);
  min-height: 100px;
}

.shadow-magic:hover:before, .shadow-magic:focus:before, .shadow-magic:active:before {
  -webkit-transform: scale(0);
  transform: scale(0);
}
.shadow-magic:hover{
  .Icon{
    color: black;
  }
}

@media(max-width: 1050px) {
  .TaxPlatformContentWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    height: fit-content;
  }
  .TaxElement {
    width: 100%;
    height: 512px;
  }
  .CITitle {
    font-size: 20px;
  }
  .CISubtitle {
    font-size: 14px;
  }
}

@media(max-width: 840px) {
  .TaxElement {
    height: 420px;
  }
}

@media(max-width: 600px) {
  .TaxPlatformContentWrapper {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.2), 0px 3px 16px rgba(0, 0, 0, 0.12), 0px 9px 12px rgba(0, 0, 0, 0.14);
  }
  .TaxElement {
    height: 400px;
  }
  .CISubtitle {
    min-height: 70px;
  }
}

@media(max-width: 410px) {
  .TaxPlatformContentWrapper {
  }
  .TaxElement {
    height: 360px;
  }
}
</style>
