import data_science from '../../assets/animations_jsons/data_science.json'
import development from '../../assets/animations_jsons/soft.json'
import mobile from '../../assets/animations_jsons/Mobile_App.json'
import integrations from '../../assets/animations_jsons/API.json'

export const slidesData = [
    {
        id: 2,
        key: "software",
        animation: development,
        title: 'Software Development',
        subtitle: 'The Systems Development Life Cycle - full process for planning, creating, testing, and deploying an information system',
        examples: ["paragraphOne", "paragraphTwo"],
        localeExamples: ["paragraphOne", "paragraphTwo", "paragraphThree", "paragraphFour"]
    },
    {
        id: 4,
        key: 'mobile',
        animation: mobile,
        title: 'Mobile Apps',
        subtitle: 'Development of various software to work on smartphones, tablets and other devices running on different operating systems',
        examples: ["paragraphOne", "paragraphTwo"],
        localeExamples: ["paragraphOne", "paragraphTwo", "paragraphThree"]
    },
    {
        id: 3,
        key: 'systems',
        animation: integrations,
        title: 'System Integration',
        subtitle: 'Bringing together the component sub-systems into one system and ensuring that the subsystems function together as a system',
        examples: ["paragraphOne", "paragraphTwo"],
        localeExamples: ["paragraphOne", "paragraphTwo", "paragraphThree"]
    },
    {
        id: 1,
        key: "bigData",
        animation: data_science,
        title: 'Big Data Analytics',
        subtitle: 'Examination and processing of large and diverse data sets to identify information, including hidden patterns, unknown correlations, market trends and customer preferences',
        examples: ["paragraphOne", "paragraphTwo"],
        localeExamples: ["paragraphOne", "paragraphTwo"]
    },
]
