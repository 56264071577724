<template>
  <div class="TopSlideWrapper">
    <div class="TextBlockWrapper">
      <div class="TBTitle" :class="{ ruLocale: $i18n.locale==='ru-RU' }">
        {{ $t(`aboutUsSLider.whiteFirstPart`)}}
        <span class="TBTitle TBTitleGreen" :class="{ ruLocale: $i18n.locale==='ru-RU' }">{{
        $t(`aboutUsSLider.greenPart`)}}</span>
        {{ $t(`aboutUsSLider.whiteSecondPart`)}}
      </div>
      <div class="TBSubtitle">{{ $t(`aboutUsSLider.subtitle`)}}</div>
    </div>
    <lottie v-observe-visibility="this.stopUnnecessaryLottie" :options="{animationData:this.logo}"
      v-on:animCreated="handleAnimation" :width="500" :height="500" />
  </div>
</template>
<script>

import "@/assets/styles/slider-styles.css"
import Lottie from 'vue-lottie';
import logo from '../../../assets/animations_jsons/logo.json'
import { ObserveVisibility } from "vue-observe-visibility";

export default {
  name: 'AboutUsSlide',
  directives: { ObserveVisibility },
  components: {
    lottie: Lottie
  },
  data() {
    return {
      logo: logo,
    }
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    play: function () {
      this.anim.play();
    },

    pause: function () {
      this.anim.pause();
    },
    stopUnnecessaryLottie(isVisible) {
      isVisible ? this.play() : this.pause()
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.TextBlockWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  margin: 25px;
  width: 50%;
  text-align: right;
}

.TBTitle {
  display: flex;
  flex-direction: column;
  text-align: initial;
  width: 650px;
  font-weight: 500;
  color: #FFFFFF;
  font-size: 72px;
  white-space: nowrap;
}

.TBTitleGreen {
  color: #BEE000;
}

.ruLocale {
  font-size: 56px;
}

@media(max-width: 1350px)
{
  .TBTitle{
    white-space: normal;
  }
}
.TBSubtitle {
  margin-top: 16px;
  text-align: start;
  width: 640px;
  color: rgba(255, 255, 255, 0.64);
  font-size: 24px;
}

@media (max-width: 1210px) {
  .TextBlockWrapper {
    width: 100%;
    align-items: center;
  }

  .TBTitle .TBSubtitle {
    width: 730px;
  }
}

@media (max-width: 761px) {
  .TextBlockWrapper {
    width: 90%;
    margin: 0 30px 0 30px;
  }

  .TBTitle {
    font-size: 2.8rem;
  }
}
</style>
