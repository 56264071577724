<template>
  <div class="MobileSliderWrapper" :class="{ ruLocale: $i18n.locale==='ru-RU' }">
<Hooper :settings="settings"  :class="{ ruLocale: $i18n.locale==='ru-RU' }">
      <Slide v-for="slide in slides" :key="slide.id">
        <MobileSliderItem  :key="slide.id" v-bind:slide="slide"/>
      </Slide>
</Hooper>
  </div>
</template>

<script>
import "@/assets/styles/global-styles.css"
import MobileSliderItem from "@/components/about_us_block/MobileSliderItem"
import {gridItems} from "@/components/about_us_block/grid-data";
import {
  Hooper,
  Slide,
} from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  name: 'MobilePicSlider',
  components: {
    Hooper,
    Slide,
    MobileSliderItem
  },
  data() {
    return {
      slides: gridItems,
      settings: {
        infiniteScroll: true,
        itemsToShow: 1.5,
        mouseDrag: true,
        centerMode: true,
        autoPlay: true,
        playSpeed: 2500,
      },
    }
  },
  methods: {
    join() {
      this.$router.push('/join-us')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.MobileSliderWrapper {
  height: 335px;
  width: 100%;
  display: none;
  margin-bottom: 30px;
}

.MobileSliderWrapper .hooper {
  height: 335px;
  width: 100%;
}

.ruLocale  {
width: 335px !important;
}
</style>
