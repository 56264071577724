<template>
  <div data-aos="zoom-in" class="JoinUsWrapper">
    <Header/>
    <div class="JoinUsContent">
      <JoinUsForm/>
    </div>
    <div class="FooterWrapper">
      <img src="../assets/logoAndIcons/logo.png" alt="iMlab"/>
      <div class="FooterLinks">
        <div class="JoinFooterButton" v-for="(link,nameEng) in this.navigationItems" v-bind:key="nameEng">
          <span @click="route(link.link)">{{ link.nameEng }}</span>
          <div class="VeticalLine"/>
        </div>
      </div>
      <div class="FooterYear">
        © {{ this.currentYear }}, iMLab
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/styles/global-styles.css"
import JoinUsForm from "../components/join_us_page/JoinUsForm"
import Header from "../components/top_block/Header"

export default {
  name: 'JoinUs',
  components: {JoinUsForm, Header},
  data() {
    return {
      navigationItems: [
        {nameEng: "Company", link: "#about"},
        {nameEng: "Tax Platform", link: "#tax"},
        {nameEng: "Services", link: "#services"},
        {nameEng: "Cases", link: "#cases"},
        {nameEng: "Our customers", link: "#partnership"},
        {nameEng: "Contact Us ", link: "#contact"},
        {nameEng: "Privacy Policy", link: ""}]
    }
  },
  methods:{
    route(link){
      this.$router.push(`/${link}`)
    }
  },
  computed: {
    currentYear: () => {
      return new Date().getFullYear()
    }
  }
}
</script>

<style scoped>
.JoinUsWrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url("../assets/images/joinBG.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}
.JoinUsWrapper .FooterWrapper{
  padding-top: 0;
}

.JoinUsContent {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  background-image: url("../assets/images/stack.png");
  background-position: right 0 bottom 74%;
  background-repeat: no-repeat;
  background-size: 940px;
  background-attachment: fixed;
}

.JoinFooterButton  {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 16px;
  letter-spacing: 0.15px;
  padding: 20px;
  color: #FFFFFF;
}
.JoinFooterButton > span {
  margin-right: 25px;
}

.JoinUsWrapper .FooterWrapper{
  height: 195px;
  display: flex;
}
.JoinUsWrapper .FooterWrapper img{
  margin-bottom: 0;
}

@media(max-width:1570px){
  .JoinUsContent{
    background-position: right -15% bottom 74%;
  }
}
@media(max-width:1100px){
  .JoinUsContent{
    background-size: 0;
  }

}
@media(max-width: 950px){
  .JoinUsWrapper .FooterWrapper{
    height: 132px;
  }
  .JoinUsWrapper  .FooterLinks{
    display: none;
  }
}
</style>
