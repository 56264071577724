<template>
    <div class="PartnersWrapper">
      <div class="HeaderTextWrapper">
        <div class="PTitle">{{ $t(`trustBlock.title.mainText`) }}<span class="PTitle Green">{{ $t(`trustBlock.title.mainGreenText`) }}</span></div>
        <div class="PSubtitle">
          {{ $t(`trustBlock.title.whiteFirst`) }}
           <span class="PSubtitle Green">{{ $t(`trustBlock.title.greenFirst`) }}</span>
           {{ $t(`trustBlock.title.whiteSecond`) }}
        </div>
      </div>
      <div class="PartnersImage"/>
    </div>
</template>

<script>

export default {
  name: 'PartnersBlock',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.PartnersWrapper {
  position: relative;
  min-height: 420px;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-image: url("../../assets/images/partnersBG.jpg");
  background-position-y: top;
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.PartnersImage {
  display: none;
}

.PTitle {
  font-size: 36px;
  font-weight: 600;
  color: #FFFFFF;
  margin-bottom: 24px;
  text-align: center;
}

.PSubtitle {
  width: 690px;
  color: rgba(255, 255, 255, 0.64);
}

.Green {
  color: #8BBC00
}

.HeaderTextWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 90px;
}

@media (max-width: 1023px) {
  .PartnersWrapper {
    padding-top: 27px;
    background-image: url("../../assets/images/background.svg");
    background-size: auto;
  }

  .PartnersImage {
    display: initial;
    min-height: 266px;
    background-image: url("../../assets/images/partners.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .HeaderTextWrapper{
    padding-top: 0;
  }
}



</style>