<template>
  <div class="TopBlockWrapper">
    <div id="stars"/>
    <div id="stars2"/>
    <div id="stars3"/>
    <Header/>
    <MainSlider/>
  </div>
  <!--    <Navigation/>-->
</template>

<script>
import Header from "@/components/top_block/Header";
import MainSlider from "@/components/top_block/MainSlider";

export default {
  name: 'TopBlock',
  components: {
    Header, MainSlider
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

// n is number of stars required
@function multiple-box-shadow ($n) {
  $value: '#{random(2000)}px #{random(2000)}px #FFF';
  @for $i from 2 through $n {
    $value: '#{$value} , #{random(2000)}px #{random(2000)}px #FFF';
  }
  @return unquote($value)
}
.TopBlockWrapper {
  max-height: min-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: radial-gradient(#2b2b2f 0%, #000000 100%);
  z-index: 0;
}

#stars {
  position: relative;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  background: transparent;
  box-shadow: multiple-box-shadow(1100);
  animation: animStar 150s linear infinite;
  z-index: 0;

  &:after {
    content: " ";
    position: fixed;
    bottom: -1500px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: multiple-box-shadow(1100);
    z-index: 0;
  }
}

#stars2 {
  position: relative;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  background: transparent;
  box-shadow: multiple-box-shadow(1100);
  z-index: 0;
  animation: animStar 150s linear infinite;

  &:after {
    content: " ";
    position: fixed;
    bottom: -500px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: multiple-box-shadow(1100);
    z-index: 0;
  }
}

#stars3 {
  position: relative;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  background: transparent;
  box-shadow: multiple-box-shadow(1110);
  z-index: 0;
  animation: animStar 150s linear infinite;

  &:after {
    content: " ";
    position: fixed;
    bottom: 750px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: multiple-box-shadow(1100);
    z-index: 0;
  }
}

@keyframes animStar {
  from {
    transform: translateY(-1200px);
  }
  to {
    transform: translateY(0px);
  }
}


</style>