import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Formatter from './formatter'
import { enLocale } from './locales/en-EN'
import { ruLocale } from './locales/ru-RU'


Vue.use(VueI18n)

const locale = 'en-US' // default locale
const formatter = new Formatter({ locale })

export default new VueI18n({
  locale,
  formatter,
  messages:   {
    'en-US': enLocale,
    'ru-RU': ruLocale
  } 
})
