import MessageFormat from 'messageformat'

export default class CustomFormatter {
  constructor (options = {}) {
    this._locale = options.locale || 'en-US'
    this._formatter = new MessageFormat(this._locale, {returnType: 'values'})
    this._caches = Object.create(null)
  }

  interpolate (message, values) {
    let fn = this._caches[message]
    if (!fn) {
      fn = this._formatter.compile(message, this._locale)
      this._caches[message] = fn
    }

    if (values !== null && values !== undefined) {
  
      const valuesEntries = Object.entries(values)
      const isNonSlotInterpolation = valuesEntries.some(entry => {
        const [key, value] = entry
        return value.hasOwnProperty('componentOptions') && isNaN(Number.parseFloat(key)) === false
      })

      if (isNonSlotInterpolation) {
  
        values = valuesEntries.map(entry => {
          const [, vnode] = entry
          if (vnode.tag !== undefined) {
            return [vnode]
          }
        }).filter(Boolean)
      }
    }
    const result = fn(values).map(piece => {
      if (!Array.isArray(piece)) {
        return piece
      }

      const hasVNodes = piece.some(part => part.hasOwnProperty('componentOptions'))
      if (hasVNodes === false) {
        return piece.join('')
      }
      return piece
    })

    return result
  }
}
