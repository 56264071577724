import rocket from "@/assets/logoAndIcons/rocket.png";
import brilliant from "@/assets/logoAndIcons/brilliant.png";
import arrow from "@/assets/logoAndIcons/arrow.png";
import hands from "@/assets/logoAndIcons/hands.png";
import laptop from "@/assets/logoAndIcons/laptop.png";
import pvt from "@/assets/logoAndIcons/pvt.png";
import shield from "@/assets/logoAndIcons/shield.png";
import iso from "@/assets/logoAndIcons/visa.png";

export const gridItems = [
    {
        id: 1,
        title: "The mission of iMlab",
        subtitle: "Is to help our clients to achieve the ultimate freedom to pursue success",
        icon: rocket
    },
    {
        id: 2,
        title: "Our values",
        subtitle: "Our values are Integrity, Quality, Teamwork, A Will to Win, Growth and Continually Increasing Competence",
        icon: brilliant
    },
    {
        id: 3,
        title: "Our goal",
        subtitle: "Is not to just solve IT issues, but to help every business unleash their full potential",
        icon: arrow
    },
    {
        id: 4,
        link: true,
        title: "Quality assurance",
        subtitle: "We are in compliance with the Payment Card Industry Data Security Standard (PCI DSS) and are listed on  the Visa Global Registry of Service Providers ",
        icon: iso
    },

    {
        id: 5,
        title: "SDLC",
        subtitle: "The Systems Development Life Cycle - full process for planning, creating, testing, and deploying an information system",
        icon: laptop
    },
    { id: 6, title: "Hi-Tech Park Member", subtitle: "HTP Belarus is the one of the largest IT-clusters in Central and Eastern Europe, global player in software production", icon: pvt },
    {
        id: 7,
        icon: shield, title: "Expert Advice",
        list: [
            "Data Protection",
            "Digital Repository",
            "Compliance Control",
            "BPM"
        ],
        ruList: [
            "разработка высоконагруженных АИС",
            "разработка мобильных приложений",
            "разработка корпоративных сетевых решений",
            "бизнес - аналитика",
            "машинное обучение и др."
        ],
    },
    {
        id: 8,
        title: "Our team",
        subtitle: "Our team consists of creative and high-inspired thinking professionals, constantly pushing the horizons",
        icon: hands
    },

]
