<template>
  <div class="AboutGridWrapper">
    <div class="GridWrapper">
      <div class="AboutItemsGrid">
        <div class="GridItem" v-for="(item, title) in this.gridItems" v-bind:key="title">
        <a v-if="item.link" href="http://www.visa.com/onthelist" target="_blank"><img width="80" height="80" v-bind:src="item.icon" v-bind:alt="item.title"></a>
          <img v-else img width="80" height="80" v-bind:src="item.icon" v-bind:alt="item.title">
          <div class="GITitle">{{ $t(`aboutUsBlock.grid.${item.id}.title`) }}</div>
          <div v-if="item.list && $i18n.locale === 'en-US'">
            <ul v-for="(subItem, i) in item.list" v-bind:key="i">
              <li class="GISubtitle">{{subItem}}</li>
            </ul>
          </div>
          <div v-if="$i18n.locale === 'ru-RU' && item.ruList">
            <ul v-for="(subItem, i) in item.ruList" v-bind:key="i">
              <li class="GISubtitle">{{subItem}}</li>
            </ul>
          </div>
          <div v-else class="GISubtitle">{{ $t(`aboutUsBlock.grid.${item.id}.text`) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {gridItems} from "@/components/about_us_block/grid-data";

export default {
  name: 'AboutUsBLock',
  data() {
    return {
      gridItems: gridItems
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.AboutGridWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 40px;
}

.GridWrapper {
  display: flex;
  justify-items: center;
}

.AboutItemsGrid {
  display: grid;
  width: 1279px;
  grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
  grid-template-rows: 1fr 1fr;
}

.GridItem {
  padding: 8px 24px 8px 24px;
  display: flex;
  flex-direction: column;
}

.GITitle {
  margin-top: 24px;
  font-weight: 600;
  text-align: start;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.87);
}

.GISubtitle {
  margin-top: 8px;
  text-align: start;
  color: rgba(0, 0, 0, 0.54);
}
</style>
