<template>
  <div class="CasesBlockWrapper">
    <div class="CasesBlockHead">
      <div class="CTitle">{{ $t(`expertise.title.mainText`) }}</div>
      <div class="CSubtitle">
        <span class="CSubtitle CSubtitleGreen">{{ $t(`expertise.title.greenFirst`) }}</span>
        {{ $t(`expertise.title.whiteFirst`) }}
        <span class="CSubtitle CSubtitleGreen">{{ $t(`expertise.title.greenSecond`) }}</span>
        {{ $t(`expertise.title.whiteSecond`) }}
      </div>
    </div>
    <CasesContainer />
  </div>
</template>

<script>
import CasesContainer from "@/components/our_cases_block/CasesContainer";

export default {
  name: 'OurCasesBlock',
  components: { CasesContainer },
}
</script>

<style scoped>
.CasesBlockWrapper {
  width: 100%;
  padding-top: 124px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-image: url("../../assets/images/casesBG.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 80px;
}

.CasesBlockHead {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  width: 43%;
}

.CTitle {
  font-size: 36px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 16px;
}

.CSubtitle {
  color: rgba(0, 0, 0, 0.54);
}

.CSubtitleGreen {
  color: #8BBC00;
}

@media (max-width: 1024px) {
  .CasesBlockHead {
    width: 80%;
  }

  .CasesBlockWrapper {
    padding-top: 25px;
  }
}
</style>
