<template>
  <div class="FooterWrapper">
    <img src="../../assets/logoAndIcons/logo.png" alt="imlab" />
    <div class="FooterLinks">
      <div class="FooterButton" v-for="(link,id) in this.navigationItems" v-bind:key="id">
        <a v-if="link.link==='/privacy'" v-bind:href="$i18n.locale==='en-US' ? '/privacy_en' : '/privacy_ru'">{{
        $t(`header.${link.link}`) }}</a>
        <a v-else v-bind:href="link.link">{{ $t(`header.${link.link}`) }}</a>
        <div v-bind:class="navigationItems.length-1===link.id?'':'VeticalLine'" />
      </div>
    </div>
    <div class="FooterYear">
      © {{ this.currentYear }}, iMLab
    </div>
  </div>
</template>

<script>

export default {
  name: 'Footer',
  data() {
    return {
      isOpen: false,
      navigationItems: [
        { id: 0, link: "#about" },
        { id: 4, link: "#partnership" },
        { id: 1, link: "#tax" },
        { id: 2, link: "#services" },
        { id: 3, link: "#cases" },
        { id: 5, link: "#contact" },
        { id: 6, link: "/privacy" }]
    }
  },
  methods: {
    openPopup() {
      this.isOpen = !this.isOpen
    }
  },
  computed: {
    currentYear: () => {
      return new Date().getFullYear()
    },
  }
}
</script>

<style>
.FooterWrapper {
  width: 100%;
  display: flex;
  padding-top: 25px;
  flex-direction: column;
  align-items: center;
  background: #434242;
}

.FooterWrapper img {
  margin-bottom: 20px;
}

.FooterLinks {
  display: flex;
}

.VeticalLine {
  height: 24px;
  width: 1px;
  border-right: 1px solid rgba(255, 255, 255, 0.24);
}


.FooterButton {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 16px;
  letter-spacing: 0.15px;
  color: #FFFFFF;
}

.FooterButton a {
  cursor: pointer;
  padding: 20px;
  text-decoration: none;
  color: #ffffff;
}

.FooterYear {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 10px 100px 10px 48px;
  color: #FFFFFF;
  background: #3A3A3A
}

@media(max-width: 950px) {
  .FooterWrapper {
    margin-top: 32px;
  }

  .FooterLinks {
    flex-direction: column;
  }

  .VeticalLine {
    display: none;
  }
}

@media(max-width: 550px) {
  .FooterWrapper {
    margin-top: 180px;
  }
}
</style>
