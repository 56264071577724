<template>
  <div class="ServiceSliderWrapper">
    <div class="ServiceSlideWrapper" v-show="slidesData" v-bind:key="slide.id" data-aos="fade-in"
      data-aos-duration="1300" data-aos-easing="">
      <div class="SliderTextBlock">
        <div class="STBTitle">
          <img src="../../assets/logoAndIcons/Circle.png" alt="circle" />
          <div>{{ $t(`servicesBlock.${slide.key}.title`) }}</div>
        </div>
        <div class="STBDescription">
          <div class="STBSubtitle">{{ $t(`servicesBlock.${slide.key}.subtitle`) }}</div>
          <div v-if="$i18n.locale === 'ru-RU'">
            <div class="DescriptionExample" v-for="(example, i) in slide.localeExamples" v-bind:key="i">
              <img src="../../assets/logoAndIcons/listBullet.png" alt="circle" />
              <div>{{ $t(`servicesBlock.${slide.key}.${example}`) }}</div>
            </div>
          </div>
          <div v-else>
            <div class="DescriptionExample" v-for="(example, i) in slide.examples" v-bind:key="i">
              <img src="../../assets/logoAndIcons/listBullet.png" alt="circle" />
              <div>{{ $t(`servicesBlock.${slide.key}.${example}`) }}</div>
            </div>
          </div>
        </div>
      </div>
      <lottie v-observe-visibility="this.stopUnnecessaryLottie"
        :options="{animationData:slide.animation, rendererSettings: {progressiveLoad: true}}" :height="400" :width="400"
        v-on:animCreated="handleAnimation" />
    </div>

  </div>
</template>

<script>
import "@/assets/styles/global-styles.css"
import "@/assets/styles/slider-styles.css"
import { slidesData } from "./slides-data"
import Lottie from 'vue-lottie';
import { ObserveVisibility } from "vue-observe-visibility";


export default {
  props: {
    currentSlide: {
      required: true,
      default: 1,
      type: Number
    },
  },
  name: 'ServiceSlider',
  directives: {
    ObserveVisibility
  },
  components: {
    lottie: Lottie
  },
  data() {
    return {
      slidesData: slidesData,
    }
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    stop: function () {
      this.anim.stop();
    },

    play: function () {
      this.anim.play();
    },

    pause: function () {
      this.anim.pause();
    },
    stopUnnecessaryLottie(isVisible) {
      isVisible ? this.play() : this.pause()
    }
  },
  watch: {

  },
  computed: {
    slide() {
      return slidesData[this.currentSlide]
    },
    isInViewport() {
      const box = document.querySelector('.ServiceSliderWrapper');
      const rect = box.getBoundingClientRect();
      const test = rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      console.log(test)
      return true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ServiceSliderWrapper {
  display: flex;
  flex-direction: row;
  letter-spacing: 0.15px;
  transition: 0.3s;
}

.STBTitle {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.STBTitle>img {
  margin-right: 10px;
  width: 18px;
  height: 18px;
}

.STBTitle>div {
  margin-right: 10px;
  font-size: 24px;
  color: #FFFFFF;
}

.STBSubtitle {
  color: rgba(255, 255, 255, 0.8);
  margin-left: 24px;
}

.STBDescription {
  border-left: 2px solid #5a5a5a;
  display: flex;
  flex-direction: column;
  width: 610px;
  text-align: start;
  margin-left: 7px;
}

.DescriptionExample {
  display: flex;
  padding: 8px 0 8px 0;
  color: rgba(255, 255, 255, 0.8);
}

.DescriptionExample>img {
  height: 27px;
  width: 48px;
}

.DescriptionExample>div {
  padding: 2px 12px 2px 12px
}

@media(max-width: 1010px) {
  .ServiceSlideWrapper {
    flex-direction: column;
  }
}

.AnimationItem * {
  margin: 0;
}
</style>
