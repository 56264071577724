<template>
  <div class="AboutTitleWrapper">
    <img src="../../assets/images/electrodes.png" alt="elect" class="Electrodes" />
    <div class="WhoBlock">
      <div class="WTitle">{{$t(`aboutUsBlock.title.mainText`) }}</div>
      <div class="WSubtitle">
        <span class="WSubtitle WGreen">{{$t(`aboutUsBlock.title.greenFirst`) }}</span>
        {{$t(`aboutUsBlock.title.whiteFirst`) }}
        <span class="WSubtitle WGreen">{{$t(`aboutUsBlock.title.greenSecond`) }}</span>
        {{$t(`aboutUsBlock.title.whiteSecond`) }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AboutTitle',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.Electrodes {
  width: 100%;
  height: 100%;
}

.AboutTitleWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.WhoBlock {
  padding-top: 104px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background-image: url("../../assets/images/whoWeAre.svg");
  background-size: 60vw;
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
}

@media(max-width: 950px) {
  .WhoBlock {
    background-size: 100vw;
    background-position-y: 26px;
  }
}

.WTitle {
  position: relative;
  top: -50px;
  left: 0;
  font-weight: bold;
  font-size: 36px;
}

.WSubtitle {
  position: relative;
  top: -40px;
  width: 695px;
  align-self: center;
}

.WGreen {
  position: relative;
  top: 0;
  color: #BEE000;
}
</style>
