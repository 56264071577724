export const ruLocale = {
  header: {
    "#about": "О НАС",
    "#partnership": "НАМ ДОВЕРЯЮТ",
    "#services": "УСЛУГИ",
    "#cases": "ПОРТФОЛИО",
    "#contact": "КОНТАКТЫ",
    "/privacy": "ПОЛИТИКА КОНФЕДЕНЦИАЛЬНОСТИ",
    "#tax": "АНАЛИТИКА ДАННЫХ"
  },
  aboutUsSLider: {
    whiteFirstPart: "ВСЁ, ЧТО ЗАДУМАНО",
    greenPart: "МОЖЕТ БЫТЬ РЕАЛИЗОВАНО",
    whiteSecondPart: "НАМИ",
    subtitle: "iMLab - команда высококвалифицированных профессионалов с большим опытом в разработке уникальных решений и проектов, использующих самые современные технологии "
  },
  aboutUsBlock: {
    title: {
      mainText: "О НАС",
      greenFirst: "ООО “АЙЭМЛЭБ",
      greenSecond: "комплексную автоматизацию бизнес-процессов и разработку решений в области информационно-коммуникационных технологий (ИКТ)",
      whiteFirst: "- одна из ведущих IT компаний Беларуси, которая предлагает своим клиентам",
      whiteSecond: "с использованием ультрасовременных методов в области дизайна и разработки программного обеспечения"
    },
    grid: {
      '1': { title: 'НАША МИССИЯ ', text: 'Помочь нашим клиентам достичь полной свободы на пути к успеху' },
      '2': { title: 'НАШИ ЦЕННОСТИ', text: 'Честность, качество, эффективность, клиентоориентированность, командная работа, воля к победе и непрерывный рост' },
      '3': { title: 'НАША ЦЕЛЬ', text: 'Не просто решить IT-вопрос, а помочь каждому бизнесу раскрыть свой потенциал' },
      '4': { title: 'КОНТРОЛЬ КАЧЕСТВА', text: 'Мы соблюдаем Стандарт безопасности данных индустрии платежных карт (PCI DSS) и внесены в Глобальный реестр поставщиков услуг Visa.' },
      '5': { title: 'РАЗРАБОТКА "под ключ"', text: 'Мы охватываем полный процесс в разработке программного обеспечения и информационных систем - от планирования до развертывания системы на ресурсах клиента' },
      '6': { title: 'РЕЗИДЕНТСТВО ПВТ', text: 'Наша компания является резидентом ПВТ - одного из крупнейших IT-кластеров Центральной и Восточной Европы ' },
      '7': { title: 'ЭКСПЕРТИЗА', text: '- разработка высоконагруженных АИС - разработка мобильных приложений - разработка корпоративных сетевых решений - бизнес-аналитика - машинное обучение и др.' },
      '8': { title: 'НАША КОМАНДА', text: 'Увлеченные, талантливые, высокоинтеллектуальные и настойчивые в совместной работе  для решения самых сложных задач профессионалы. Мы гордимся каждым специалистом в нашей команде и считаем, что именно они делают нашу компанию особенной' },
    }
  },
  trustBlock: {
    title: {
      mainText: "НАМ ",
      mainGreenText: "ДОВЕРЯЮТ",
      greenFirst: "клиентами ",
      whiteFirst: "Нашими ",
      whiteSecond: "являются как небольшие и средние  компании, так и крупные корпорации из различных сфер бизнеса и отраслей экономики: государственные предприятия, предприятия крупного ритейла, банки, предприятия сферы HORECA и службы доставки, и другие"
    }
  },
  taxPlatform: {
    title: "ПЛАТФОРМА ПО АНАЛИТИКЕ ДАННЫХ",
    subtitle: "Мы помогаем нашим клиентам процветать в эпоху перемен за счет аналитики данных, отчетов и машинного обучения, способствующих развитию бизнеса и позволяющих относиться к трансформации как к непрерывному процессу совершенствования",
    grid: {
      data: { title: 'Сбор данных', subtitle: 'Мы помогаем собираем информацию об объектах анализа из источников, к которым  есть законный доступ' },
      storage: { title: 'Надежное хранение', subtitle: 'Хранение данных на защищенных и аттестованных ресурсах' },
      analytics: { title: 'Аналитика, отчетность  и прогнозирование', subtitle: 'Все структурированные и неструктурированные данные трансформируются и объединяются для дальнейшего использования. Прогнозы, созданные на основе данных, дают возможность планировать, инвестировать, масштабировать и делать новые открытия' },
      validation:{ title: 'Криптография', subtitle: 'Разработка и внедрение криптографических средств защиты информации'}  }
  },
  servicesBlock: {
    title: {
      mainText: 'УСЛУГИ',
      whiteFirst: 'Направления  нашей деятельности, которые помогут ',
      whiteSecond: ' для развития ',
      greenFirst: 'открыть новые перспективы ',
      greenSecond: 'вашего бизнеса'
    },
    bigData: {
      title: 'Обработка и анализ данных',
      subtitle: 'Обработка больших и разнообразных наборов данных для выявления информации, включая скрытые закономерности, неизвестные корреляции, рыночные тенденции и предпочтения клиентов ',
      paragraphOne: 'Разработка высоконагруженных информационных систем, в том числе на базе микросервисной архитектуры взаимодействия клиент-серверных приложений, включая в себя системы защиты информации, хранения архива информации, систему обработки информации и быстрого доступа к данным (OLAP-приложений)',
      paragraphTwo: 'Сбор и обработка информации с формированием интерактивных отчетов, выполненных в том числе с помощью средств визуальной аналитики I',
    },
    software: {
      title: 'Разработка программного обеспечения ',
      subtitle: 'Команда разработчиков iMLab  предлагает полный цикл разработки программного обеспечения  - от бизнес анализа и дизайна до тестирования, внедрения и технической поддержки. Мы применяем различные языки программирования, фреймворки, библиотеки и иные инструменты в зависимости от требований проекта ',
      paragraphOne: 'Основные технологии: Java, Kotlin, Rust, C++, PHP, Javascript, Python',
      paragraphTwo: "Веб-технологии: Javascript, React.js, Vue.js",
      paragraphThree: 'Мобильные технологии: Android, iOS, кроссплатформенная разработка',
      paragraphFour: "Базы данных: Oracle, PostgreSQL, MySQL, MongoDB",
    },
    systems: {
      title: 'Управление IT-инфраструктурой',
      subtitle: 'Команда инженеров iMLab использует новейшие решения в области планирования, проектирования, моделирования, развертывания и масштабирования IT-инфраструктуры',
      paragraphOne: 'Проведение аудита системы компании-заказчика ',
      paragraphTwo: 'Проектирование масштабируемой и надежной IT-среды ',
      paragraphThree: 'Мониторинг и управление системой'
    },
    mobile: {
      title: 'Разработка мобильных приложений',
      subtitle: 'Разработка различного ПО для работы на смартфонах, планшетах и других устройствах под управлением разных операционных систем',
      paragraphOne: 'Нативная разработка клиент-серверных мобильных приложений под iOS и Android',
      paragraphTwo: 'Кроссплатформенная мобильная разработка клиент-серверных мобильных приложений ',
      paragraphThree: 'Интеграция мобильного приложения заказчика с внешними системами: CRM, ERP, товароучетной системой, программой лояльности, маркетинговой платформой заказчика и другими '
    },
    pagination: {
      back: 'Назад',
      forward: 'Далее',
      numbers: 'из'
    }
  },
  expertise: {
    title: {
      mainText: 'ПОРТФОЛИО',
      greenFirst: 'Компания iMLab ',
      greenSecond: 'которая занимается разработкой программного обеспечения',
      whiteFirst: 'является одновременно продуктовой и аутсорсинговой IT-компанией,',
      whiteSecond: ' и оказывает услуги  в сфере IT',
    },
    grid: {
      '1': {
        title: 'АИС ПКС iKassa',
        subtitle: 'Автоматизированная информационная система «Программная кассовая система iKassa» ',
        listHeader: 'АИС ПКС “iKassa” взаимодействует с системой контроля кассового оборудования МНС (СККО). Функционал системы разработан на базе  микросервисной архитектуры взаимодействия клиент-серверных приложений, включает: ',
        '1': 'систему защиты информации, хранения архива информации, определенной Постановлением Совета министров №924/16 ',
        '2': 'систему обработки информации и быстрого доступа к данным (OLAP)',
        '3': '   формирование, прием, передача документов и событий, формируемых на программных кассах в форматах и требованиях, определенных для взаимодействия с системой ККО МНС',
      },
      '2': {
        title: 'iKassaPay',
        subtitle: 'Автоматизированная система приема платежных карт на мобильных устройствах',
        listHeader: 'Разработка и внедрение автоматизированной системы по приему платежных банковских карт на мобильных устройствах.',
        '1':'система аттестована международным консорциум PCI DSS сертификат уровня  Level 1, ROC от 17.02.2021',
        '2':'система сертифицирована в платежных системах Visa, Mastercard, НСПК МИР',
        '3':'функционал системы включает клиент-серверную микросервисную архитектуру, систему защиты информации'
      },
      '3': {
        title: 'АИС “Курьер”',
        subtitle: 'Автоматизированная система по управлению сбора заказов для службы доставки',
        listHeader: 'Разработка и внедрение автоматизированной системы по управлению и сбором заказов для службы доставки с объектов их  хранения. Функционал включает:',
        '1': 'клиент-серверное приложение, интегрированное в товарную учетную систему Заказчика',
        '2': ' мобильное приложение для проверки, сбора товаров, обмена данными с товарной учетной системой',
        '3': 'модуль управления заказами, курьерами KPI`s'
      },
      '4': {
        title: 'Billing',
        subtitle: 'Система биллинга',
        listHeader: 'Система включает',
        '1':'сбор информации о поступающих платежах из разных источников',
        '2':'их обработку, соотнесения с платежами абонентов предприятия',
        '3':'формирование и отпуск существующих услуг предприятия на сторону потребителя',
        '4':'формирования бухгалтерских  и иных документов.',
      },
      '5': {
        title: 'АИС “Клиент”',
        subtitle: 'Автоматизированная система управления лояльностью',
        listHeader: 'Разработка и внедрение автоматизированной системы по управлению лояльностью  компании. Система разработана на базе  клиент-серверной архитектуры и включает:',
        descriptions: ["Приложения интегрированы с системой лояльности заказчика, товароучетной системой, программно-кассовой системой"],
        '1':'клиентское мобильное приложение',
        '2':'административное веб-приложение',
        '3':'формирование и отпуск существующих услуг предприятия на сторону потребителя',
        '4':' формирования бухгалтерских  и иных документов.',
      },
      '6': {
        title: 'Pipe (платформа видеосвязи)',
        subtitle: 'Платформа видеосвязи',
        listHeader: 'Платформа видеосвязи и видеоконференций с шифрованными каналами стриминга данных',

      },
    }
  },
  contact: {
    mainText: "ОБРАТНАЯ СВЯЗЬ",
    subtitle: 'Если у вас есть какие либо вопросы, пожалуйста, свяжитесь с нами',
    formText:{
      name: "Имя",
      phone: "Телефон",
      email: "Электронная почта",
      subject: "Тема",
      message: "Ваше сообщение",
      limitText: "Максимум символов",
      personalData: "Я согласен на обработку персональных данных",
      button:"ОТПРАВИТЬ",
      closeButton: "ЗАКРЫТЬ",
      address: 'Адрес',
      thanks: 'Спасибо за обращение!',
      responseText: 'Ваше сообщение было отправлено. Наши менеджеры свяжутся с вами как можно скорее!',
      addressText: '220012, Республика Беларусь, г. Минск, ул. Толбухина 3А, оф. 106а',
    }
  }
}