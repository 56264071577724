<template>
  <div class="BothHeadersWrapper">
    <div class="HeaderWrapper">
      <div class="LogoWithButton">
        <img @click="home" src="../../assets/logoAndIcons/logo.png" alt="imlab.by" />
      </div>
      <div class="HeaderNavigationWrapper">
        <div v-for="(navItem, name) in this.navigationItems" v-bind:key="name">
          <a @click="route(navItem.link)" v-bind:href="navItem.link" class="ContainedButton">{{
          $t(`header.${navItem.link}`) }}</a>
        </div>
        <div class="locale">
          <div @click="$i18n.locale = 'en-US'" class="localeSwitch" :class="{ selectedLocale: $i18n.locale==='en-US' }">
            EN
          </div>
          <div @click="$i18n.locale = 'ru-RU'" class="localeSwitch" :class="{ selectedLocale: $i18n.locale==='ru-RU' }">
            RU
          </div>
        </div>
      </div>
    </div>
    <div class="MobileHeaderWrapper">
      <img src="../../assets/logoAndIcons/logo.png" alt="imlab.by" />
      <div class="locale">
            <div @click="$i18n.locale = 'en-US'" class="localeSwitch"
              :class="{ selectedLocale: $i18n.locale==='en-US' }">
              EN
            </div>
            <div @click="$i18n.locale = 'ru-RU'" class="localeSwitch"
              :class="{ selectedLocale: $i18n.locale==='ru-RU' }">
              RU
            </div>
          </div>
      <div data-aos="fade-down" v-if="isBurgerOpen" class="MobileHeader">
        <div class="MobileHeaderLogoWithButton">
          <img src="../../assets/logoAndIcons/logo.png" alt="imlab.by" />
          <div class="locale">
            <div @click="$i18n.locale = 'en-US'" class="localeSwitch"
              :class="{ selectedLocale: $i18n.locale==='en-US' }">
              EN
            </div>
            <div @click="$i18n.locale = 'ru-RU'" class="localeSwitch"
              :class="{ selectedLocale: $i18n.locale==='ru-RU' }">
              RU
            </div>
          </div>
          <img @click="openBurger" src="../../assets/logoAndIcons/Button.png" width="48" height="48" alt="burger" />
        </div>
        <div class="MobileMenu">
          <div v-for="(navItem, name) in this.navigationItems" v-bind:key="name">
            <a @click="openBurger" v-bind:href="navItem.link" class="ContainedButton">{{ $t(`header.${navItem.link}`)
            }}</a>
          </div>
        </div>
      </div>
      <img @click="openBurger" src="../../assets/logoAndIcons/Burger.png" alt="burger" />
    </div>
  </div>
</template>

<script>
import "@/assets/styles/global-styles.css"

export default {
  name: 'Header',
  components: {},
  data() {
    return {
      locale: 'en-US',
      isBurgerOpen: false,
      navigationItems: [
        { link: "#about" },
        { link: "#partnership" },
        { link: "#services" },
        //{nameRu: "", nameEng: "HOW WE DO IT", link: "#how"},
        { link: "#cases" },
        // {nameRu: "", nameEng: "CAREERS ", link: "#careers"},
        { link: "#contact" }]
    }
  },
  methods: {
    home() {
      this.$router.push('/')
    },
    openBurger() {
      this.isBurgerOpen = !this.isBurgerOpen
    },
    route(link) {
      this.$router.push(`/${link}`)
    },
  },
}
</script>

<style scoped>
.locale {
  margin-left: 32px;
  display: flex;
  color: white;
}

.locale>div {
  margin-right: 16px;
}

.localeSwitch {
  border: 2px solid white;
  padding: 4px;
  border-radius: 8px;
}

.selectedLocale {
  background: white;
  color: black;
}

.BothHeadersWrapper {
  z-index: 3;
}

.LogoWithButton {
  cursor: pointer;
}

.HeaderWrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 16px 24px 16px 24px;
}

.MobileHeaderWrapper {
  display: none;
}

@media (max-width: 1100px) {
  .HeaderWrapper {
    display: none;
  }

  .MobileHeaderWrapper {
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px 16px 24px;
    display: flex;
  }
}

.MobileHeader {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 95%;
  height: 100%;
  z-index: 2;
  background: #434242;
  box-shadow: 5px 0px 6px rgba(0, 0, 0, 0.2), 3px 0px 16px rgba(0, 0, 0, 0.12), 9px 0px 12px rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px 16px 24px;
}

.MobileMenu {
  height: 630px;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.HeaderNavigationWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.HeaderNavigationWrapper .ContainedButtonBorder {
  margin-right: 16px;
}

.MobileHeaderLogoWithButton {
  cursor: pointer;
  display: flex;
  width: 95%;
  align-items: center;
  justify-content: space-between;
}
</style>
