<template>
<div>
    <pdf src="privacy_en.pdf" :page="1" style="width:100%;margin:20px auto;" :resize="true"/>
    <pdf src="privacy_en.pdf" :page="2" style="width:100%;margin:20px auto;" :resize="true"/>
    <pdf src="privacy_en.pdf" :page="3" style="width:100%;margin:20px auto;" :resize="true"/>
    <pdf src="privacy_en.pdf" :page="4" style="width:100%;margin:20px auto;" :resize="true"/>
    <pdf src="privacy_en.pdf" :page="5" style="width:100%;margin:20px auto;" :resize="true"/>
  </div>
</template>

<script>
import pdf from 'pdfvuer';

export default {
  name: 'PrivacyPolicyEn',
  components: {
    pdf,
  },
};
</script>
