import Vue from 'vue'
import Router from 'vue-router'
import Home from "@/pages/Home";
import JoinUs from "@/pages/JoinUs";
import PrivacyPolicyRu from "@/pages/PrivacyPolicyRu"
import PrivacyPolicyEn from "@/pages/PrivacyPolicyEn"

Vue.use(Router)

export default new Router({
    mode:"history",
    routes : [
        {
            path: "/",
            component: Home,
        },
        {
            path: "/join-us",
            component: JoinUs
        },
        {
            path: "/privacy_en",
            component: PrivacyPolicyEn
        },
        {
            path: "/privacy_ru",
            component: PrivacyPolicyRu
        },
    ]
})