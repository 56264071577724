<template>
  <div class="JoinFormWrapper">
    <div class="InputsWrapper">
      <div>
        <div class="CTitle">JOIN OUR TEAM</div>
        <div class="CSubtitle">To achieve our goals, we are looking for a job for professionals in these areas</div>
      </div>
      <div class="FirstRow">
        <input v-model="formData.firstName"
               v-on:change="$emit('input',$event.target.value)" class="InputWrapper"
               placeholder="First name"/>
        <input v-model="formData.lastName"
               v-on:change="$emit('input',$event.target.value)" class="InputWrapper"
               placeholder="Last name"/>
      </div>
      <input v-model="formData.email" type="email"
             v-on:change="$emit('input',$event.target.value)" class="InputWrapper"
             placeholder="E-mail"/>
      <select v-model="formData.language"
              v-on:change="$emit('input',$event.target.value)" class="InputWrapper">
        <option v-for="(option,value) in this.langOptions" v-bind:value="option.value" v-bind:key="value">
          {{ option.title }}
        </option>
      </select>
      <input v-model="formData.subject"
             v-on:change="$emit('input',$event.target.value)" class="InputWrapper"
             placeholder="Subject"/>
      <label for="file-upload" class="custom-file-upload">
        <i class="fa fa-cloud-upload"></i>Upload your CV
      </label>
      <input id="file-upload" type="file"/>
      <input v-model="formData.linkedIn"
             v-on:change="$emit('input',$event.target.value)" class="InputWrapper"
             placeholder="LinkedIn profile link"/>
      <div class="ContainedButtonBorder">
        <button class="GreenContainedButton">SEND</button>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'JoinUsForm',
  data() {
    return {
      formData: {
        firstName: "",
        lastName: "",
        email: "",
        subject: "",
        language: "",
        cv: {},
        linkedIn: ""
      },
      langOptions: [
        {value: "", title: "Language proficiency"},
        {value: 'A1', title: "A1 - Beginner"},
        {value: 'A2', title: "A2 - Elementary"},
        {value: 'B1', title: "B1 - Intermediate"},
        {value: 'B2', title: "B2 - Upper Intermediate"},
        {value: 'C1', title: "C1 - Advanced"},
        {value: 'C2', title: "C2 - Proficient"},
      ]
    }
  }
}
</script>

<style scoped>
.JoinFormWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.CTitle {
  font-size: 36px;
  font-weight: 600;
  color: #FFFFFF;
  margin-bottom: 10px;
  text-align: left;
  padding: 60px 10px 10px 10px;
}

.CSubtitle {
  color: rgba(255, 255, 255, 0.64);
  margin-left: 10px;
  margin-bottom: 16px;
  width: 100%
}

.InputsWrapper {
  width: 650px;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 7%;
}
.InputsWrapper .ContainedButtonBorder{
  margin: 10px;
}

.InputsWrapper .ContainedButtonBorder .GreenContainedButton {
  width: 325px;
}

input {
  display: block;
  cursor: pointer;
  margin: 10px;
  background: transparent;
  padding: 10px;
  color: rgba(255, 255, 255, 0.54);
  border: 1px solid rgba(255, 255, 255, 0.48);
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  display: block;
  cursor: pointer;
  margin: 10px;
  background: transparent;
  padding: 10px;
  color: rgba(255, 255, 255, 0.54);
  border: 1px solid rgba(255, 255, 255, 0.48);
}

select {
  display: block;
  margin: 10px;
  background: transparent;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.48);
}
select option {
  color: dimgray;
}

input:focus-visible {
  outline: none;
  border: 1px solid #FFFFFF;
}

input:hover {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}

input::placeholder {
  color: rgba(255, 255, 255, 0.54);
}

select {
  color: rgba(255, 255, 255, 0.54);
}

.FirstRow {
  display: flex;
  justify-content: space-between;
}

.FirstRow > input {
  width: 48%;
}

.WarningText {
  color: rgba(255, 255, 255, 0.54);
  font-size: 12px;
  text-align: left;
  margin-left: 20px;
}
@media(max-width:1100px){
  .InputsWrapper{
    width: 88%;
  }
  .InputsWrapper .GreenContainedButton {
    width: 97%;
  }
  .InputsWrapper .ContainedButtonBorder {
    width: 280px;
  }
}

</style>
