<template>
  <div v-if="$i18n.locale === 'ru-RU'" class="app">
    <TopBlock/>
    <AboutUsBLock data-aos="fade-up" data-aos-duration="300" id="about"/>
    <ServicesBlock data-aos="fade-up" data-aos-duration="300" id="services"/>
    <TaxPlatform data-aos="fade-up" data-aos-duration="300" id="tax"/>
    <OurCasesBlock data-aos="fade-up" data-aos-duration="300" id="cases"/>
    <PartnersBlock data-aos="fade-up" data-aos-duration="300" id="partnership"/>
    <ContactBlock data-aos="fade-up" data-aos-duration="300" id="contact"/>
    <Footer/>
  </div>
  <div v-else>
    <TopBlock/>
    <AboutUsBLock data-aos="fade-up" data-aos-duration="300" id="about"/>
    <PartnersBlock data-aos="fade-up" data-aos-duration="300" id="partnership"/>
    <TaxPlatform data-aos="fade-up" data-aos-duration="300" id="tax"/>
    <ServicesBlock data-aos="fade-up" data-aos-duration="300" id="services"/>
    <OurCasesBlock data-aos="fade-up" data-aos-duration="300" id="cases"/>
    <ContactBlock data-aos="fade-up" data-aos-duration="300" id="contact"/>
    <Footer/>
  </div>
</template>

<script>
import "@/assets/styles/global-styles.css"
import TopBlock from "@/components/top_block/TopBlock";
import AboutUsBLock from "@/components/about_us_block/AboutUsBLock";
import TaxPlatform from "@/components/tax_platform_block/TaxPlatform";
import ServicesBlock from "@/components/services_block/ServicesBlock";
import OurCasesBlock from "@/components/our_cases_block/OurCasesBlock";
import PartnersBlock from "@/components/partners_block/PartnersBLock";
import ContactBlock from "@/components/contact_block/ContactBlock"
import Footer from "@/components/footer/Footer"

export default {
  name: 'Home',
  components: {PartnersBlock, OurCasesBlock, TopBlock, AboutUsBLock, TaxPlatform, ServicesBlock, ContactBlock, Footer},
}
</script>

<style>
.app {
  display: grid;
  width: 100vw;
  height: 100%;
  grid-template-columns: repeat(auto-fill, minmax(100vw, 100vw));
  grid-template-rows: repeat(auto-fit, minmax(1fr,100vh));
  grid-column-gap: 0;
}
.app>*{
  width: 100%;
}
</style>