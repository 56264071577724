<template>
  <div class="TaxPlatformWrapper">
    <div class="HeaderColor" :class="{ruLocale: $i18n.locale==='ru-RU'}">
      <div class="TaxPlatformHead">
        <div class="TPTitle">{{ $t(`taxPlatform.title`) }}</div>
        <div class="TPSubtitle">{{ $t(`taxPlatform.subtitle`) }}</div>
      </div>
    </div>
    <TaxPlatformContent />
  </div>
</template>

<script>
import "@/assets/styles/global-styles.css"
import TaxPlatformContent from "@/components/tax_platform_block/TaxPlatformContent";

export default {
  name: 'TaxPlatform',
  components: { TaxPlatformContent }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.TaxPlatformWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  background: transparent;
  max-height: fit-content;
}

.HeaderColor {
  width: 100%;
  height: fit-content;
  background: linear-gradient(74.88deg, #2B2B2B 0%, #353535 100%);
  padding: 48px 0;
}

.TaxPlatformHead {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 150px;
  background-image: url("../../assets/images/taxPlatform.svg");
  background-size: 60vw;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
}

@media(max-width: 950px) {
  .TaxPlatformHead {
    background-size: 100vw;
    background-position-y: 26px;
    max-width: 100%;
    text-align: center;
    height: 250px;
  }

}

.TPTitle {
  font-size: 36px;
  font-weight: 600;
  color: #FFFFFF;
  margin-bottom: 16px;
}

.TPSubtitle {
  color: rgba(255, 255, 255, 0.64);
  width: 60%;
}

@media (max-width: 670px) {
  .ruLocale {
    padding: 70px 0 48px 0;
  }
}

@media (max-width: 520px) {
  .ruLocale {
    padding: 120px 0 48px 0;
  }
}

@media (max-width: 394px) {
  .TPSubtitle {
    margin-bottom: 15px;
  }
  .ruLocale {
    padding: 220px 0 48px 0;
  }


}
</style>
