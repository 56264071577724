<template>
  <div class="ContactWrapper">
    <ContactForm/>
    <div class="InfoFooter">
      <div class="InfoFooterItem" v-for="(item, title) in this.infoItems" v-bind:key="title">
        <img v-bind:src="item.icon" v-bind:alt="item.title" class="iconFooter">
        <div>
          <h3>{{ $t(`contact.formText.${item.title}`) }}</h3>
          <h4 v-if="item.title === 'address'">{{ $t(`contact.formText.${item.subtitle}`) }}</h4>
          <h4 v-else>{{ item.subtitle }}</h4>
        </div>
      </div>
    </div>
    <yandex-map
        :coords="coords"
        :zoom="19"
        :settings="this.settings"
        @click="onClick"
        ymap-class=""
    >
      <ymap-marker
          :coords="coords"
          marker-id="123"
          hint-content="We located here!"
      />
    </yandex-map>
  </div>
</template>

<script>
import {yandexMap, ymapMarker} from 'vue-yandex-maps'
import {settings} from '/src/components/contact_block/yMapConf'
import ContactForm from '@/components/contact_block/ContactForm'
import phone from '/src/assets/logoAndIcons/phone.png'
import location from '/src/assets/logoAndIcons/location.png'
import mail from '/src/assets/logoAndIcons/mail.png'

export default {
  name: 'ContactBlock',
  components: {
    ContactForm, yandexMap, ymapMarker
  },
  data() {
    return {
      coords: [53.92552, 27.61216],
      settings: settings,
      infoItems: [
        {title: "address", subtitle: "addressText", icon: location},
        {title: "phone", subtitle: "+375 (29) 107-43-43", icon: phone},
        {title: "email", subtitle: "info@imlab.by", icon: mail}
      ]
    }
  },
  methods: {
    onClick(e) {
      this.coords = e.get('coords');
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ContactWrapper {
  height: 978px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-image: url("../../assets/images/contactBG.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -35px;
}

.ContactWrapper .ymap-container {
  width: 100%;
  height: 361px;
}

.InfoFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px 24px 16px 0;
  background: rgba(5, 20, 43, 0.24);
  text-align: left;
}

.InfoFooterItem {
  display: flex;
  margin-right: 70px;
}

.InfoFooterItem > img {
  margin-right: 21px;
}
.InfoFooterItem  h3 {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.54);
}
.InfoFooterItem  h4 {
  padding-top: 0;
  color: #FFFFFF;
}

@media(max-width: 950px){
  .InfoFooter{
    flex-direction: column;
    align-items: flex-start;
    margin-top: 130px;
  }
  .InfoFooterItem{
    margin: 10px;
  }
  .ContactWrapper .ymap-container {
    width: 100%;
    height: 220px;
  }
}


.iconFooter{
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
}

</style>
