<template>
  <div class="ContactFormWrapper">
    <div v-if="isRequestModalOn" class="RequestSuccessModalWrapper">
      <div class="RequestSuccessModal">
        <h2>
          {{ $t(`contact.formText.thanks`) }}
        </h2>
        <h3>
          {{ $t(`contact.formText.responseText`) }}
        </h3>
        <div class="ContainedButtonBorder">
          <button @click="this.closeModal" class="GreenContainedButton">{{ $t(`contact.formText.closeButton`) }}</button>
        </div>
      </div>
    </div>
    <div>
      <div class="CTitle">{{ $t(`contact.mainText`) }}</div>
      <div class="CSubtitle">{{ $t(`contact.subtitle`) }}</div>
    </div>
    <div class="InputsWrapper">
      <div class="FirstRow">
        <input v-model="formData.name" v-on:change="$emit('input', $event.target.value)" class="InputWrapper"
          :placeholder="$t(`contact.formText.name`)" />
        <input v-model="formData.phone" v-on:change="$emit('input', $event.target.value)" class="InputWrapper"
          :placeholder="$t(`contact.formText.phone`)" />
        <input v-model="formData.email" type="email" v-on:change="$emit('input', $event.target.value)"
          class="InputWrapper" :placeholder="$t(`contact.formText.email`)" />
      </div>
      <input v-model="formData.subject" v-on:change="$emit('input', $event.target.value)" class="InputWrapper"
        :placeholder="$t(`contact.formText.subject`)" />
      <input v-model="formData.message" v-on:change="$emit('input', $event.target.value)" class="InputWrapper"
        :placeholder="$t(`contact.formText.message`)" />
      <span class="WarningText">{{ $t(`contact.formText.limitText`) }}: {{ this.formData.message.length }} {{ $t(`servicesBlock.pagination.numbers`) }} 228</span>
      <div class="PermCheckbox">
        <input type="checkbox" v-model="useDataPermission" />
        <div>{{ $t(`contact.formText.personalData`) }}</div>
      </div>
      <div v-if="useDataPermission" class="ContainedButtonBorder">
        <button @click="this.sendQuestion" class="GreenContainedButton">{{ $t(`contact.formText.button`) }}</button>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'ContactForm',
  data() {
    return {
      useDataPermission: true,
      isRequestModalOn: false,
      formData: {
        name: "",
        email: "",
        subject: "",
        message: "",
        phone: ""
      },
    }
  },
  methods: {
    async sendQuestion() {
      await fetch(process.env.VUE_APP_CONTACT_URL, {
        method: 'POST',
        headers: {
          'content-type': "application/json"
        },
        body: JSON.stringify(this.formData)
      }).then(() => {
        this.isRequestModalOn = true
        this.formData = {
          name: "",
          email: "",
          subject: "",
          message: "",
          phone: ""
        }
      })
        .catch((e) => { console.log(e) })
    },
    closeModal() {
      this.isRequestModalOn = false
    }
  }
}
</script>

<style scoped>
.ContactFormWrapper {
  position: relative;
  height: 425px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 140px;
  margin-bottom: 180px;
}

.CTitle {
  font-size: 36px;
  font-weight: 600;
  color: #FFFFFF;
  margin-bottom: 16px;
  text-align: center;
  padding-top: 124px;
}

.CSubtitle {
  color: rgba(255, 255, 255, 0.64);
  margin-left: 10px;
}

.InputsWrapper {
  width: 670px;
  display: flex;
  flex-direction: column;
}

.ContainedButtonBorder {
  margin-left: 10px;
}

.InputsWrapper .ContainedButtonBorder .GreenContainedButton {
  width: 325px;
}

input {
  display: block;
  margin: 10px;
  background: transparent;
  padding: 10px;
  color: rgba(255, 255, 255, 0.54);
  border: 1px solid rgba(255, 255, 255, 0.48);
}

input:focus-visible {
  outline: none;
  border: 1px solid #FFFFFF;
}

input:hover {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}

input::placeholder {
  color: rgba(255, 255, 255, 0.54);
}

.FirstRow {
  display: flex;
  justify-content: space-between;
}

.FirstRow>input {
  width: 48%;
}

.WarningText {
  color: rgba(255, 255, 255, 0.54);
  font-size: 12px;
  text-align: left;
  margin-left: 20px;
}

.PermCheckbox {
  display: flex;
  align-items: center;
  padding-top: 10px;
  margin-top: 32px;
  color: #FFFFFF;
}

@media (max-width: 960px) {
  .InputsWrapper {
    width: 95%;
  }

  .ContactFormWrapper {
    margin-left: 16px;
    width: 96%;
    max-height: 425px;
  }
}

@media (max-width: 700px) {
  .FirstRow {
    flex-direction: column;
  }

  .FirstRow>input {
    width: inherit;
  }

  .InputsWrapper .ContainedButtonBorder .GreenContainedButton {
    width: 270px;
  }

  .ContainedButtonBorder {
    margin: 12px;
  }
}

.RequestSuccessModalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 1000;
}

.RequestSuccessModal {
  justify-content: space-between;
  align-self: center;
  justify-self: center;
  margin: 0 auto;
  align-items: center;
  position: fixed;
  border-radius: 7px;
  background: #F5F5F5;
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  height: 150px;
  z-index: 5;
  top: 20%;
  width: 300px;
}
</style>
