<template>
  <div class="CasesWrapper">
    <div v-for="(caseItem, id) in this.cases" v-bind:key="id">
      <Case v-bind:case="caseItem"/>
    </div>
  </div>
</template>

<script>
import Case from "@/components/our_cases_block/Case";
import {caseData} from "@/components/our_cases_block/case-data";

export default {
  name: 'CasesContainer',
  components: {Case},
  data() {
    return {
      cases: caseData
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.CasesWrapper {
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(2, minmax(256px, 1fr));
  grid-template-rows: 0.5fr 0.5fr 0.5fr;
  grid-column-gap: 24px;
  grid-row-gap: 80px;
  padding: 25px;
}

@media (max-width: 1024px) {
  .CasesWrapper {
    grid-template-columns:1fr;
  }
}

@media (max-width: 524px) {
.CasesWrapper{
  display: flex;
  flex-direction: column;
}
}

</style>
