
export const caseData = [
    {
        id: 1,
        row: 'odd',
        ruListHeader: true,
        engListHeader: true,
        descriptions: [0],
        engList: [1, 2, 3],
        ruList: [1, 2, 3],
    },
    {
        id: 2,
        row: 'odd',
        ruListHeader: true,
        engListHeader: true,
        engList: [1, 2, 3, 4, 5],
        ruList: [1, 2, 3],
    },
    {
        id: 3,
        row: 'even',
        ruListHeader: true,
        engListHeader: true,
        engList: [1, 2, 3],
        ruList: [1, 2, 3],
    },
    {
        id: 4,
        row: 'even',
        ruListHeader: true,
        descriptions: [0,1],
        ruList: [1, 2, 3, 4],
    },
    {
        id: 5,
        row: 'odd',
        ruListHeader: true,
        descriptions: [0,1,2],
        ruDescriptions: [0],
        ruList: [1, 2, 3, 4],
    },
    {
        id: 6,
        row: 'odd',
        ruListHeader: true,
        descriptions: [0],
    },
]

