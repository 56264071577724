export const enLocale = {
  header: {
    "#about": "ABOUT",
    "#partnership": "PARTNERSHIP",
    "#services": "SERVICES",
    "#cases": "EXPERTISE",
    "#contact": "CONTACT US",
    "/privacy": "PRIVACY POLICY",
    "#tax": "TAX PLATFORM"
  },
  aboutUsSLider: {
    whiteFirstPart: "Workspace for the",
    greenPart: "Innovative Software",
    whiteSecondPart: "Solutions",
    subtitle: "iMLab Team is a high-skilled team of professionals with great experience in developing unique projects using modern technologies"
  },
  aboutUsBlock: {
    title: {
      mainText: "WHO WE ARE?",
      greenFirst: "iMlab ltd",
      greenSecond: "information communication technology (ICT) solutions, products and services",
      whiteFirst: "- is one of the leading IT and web design company in Belarus that provides",
      whiteSecond: "using ultra-modern technologies"
    },
    grid: {
      '1': { title: 'The mission of iMlab', text: 'Is to help our clients to achieve the ultimate freedom to pursue success' },
      '2': { title: 'Our values', text: 'Our values are Integrity, Quality, Teamwork, A Will to Win, Growth and Continually Increasing Competence' },
      '3': { title: 'Our goal', text: 'Is not to just solve IT issues, but to help every business unleash their full potential' },
      '4': { title: 'Quality assurance', text: 'We are in compliance with the Payment Card Industry Data Security Standard (PCI DSS) and are listed on  the Visa Global Registry of Service Providers' },
      '5': { title: 'SDLC', text: 'The Systems Development Life Cycle - full process for planning, creating, testing, and deploying an information system' },
      '6': { title: 'Hi-Tech Park Member', text: 'HTP Belarus is the one of the largest IT-clusters in Central and Eastern Europe, global player in software production' },
      '7': { title: 'Expert Advice', text: '' },
      '8': { title: 'Our team', text: 'Our team consists of creative and high-inspired thinking professionals, constantly pushing the horizons' },
    }
  },
  trustBlock: {
    title: {
      mainText: "WE ARE ",
      mainGreenText: "TRUSTED",
      greenFirst: "collaboration ",
      whiteFirst: "We value ",
      whiteSecond: "and strive for the highest standards of excellence in our business moving together towards new goals and achievements."
    }
  },
  taxPlatform: {
    title: "TAX PLATFORM",
    subtitle: "A place to combine multiple systems and processes into one data platform",
    grid: {
      data: { title: 'Data collection', subtitle: 'Data is securely stored in distributed cloud storage' },
      storage: { title: 'Reliable storage', subtitle: 'Data is securely stored in distributed cloud storage' },
      analytics: { title: 'Validation and Standardization', subtitle: 'All structured and unstructured data is transformed and combined for further use' },
      validation: { title: 'Cryptography', subtitle: 'Development and implementation of cryptographic information protection facility' },
    }
  },
  servicesBlock: {
    title: {
      mainText: 'SERVICES',
      whiteFirst: 'Areas of activity that ',
      whiteSecond: ' for the development',
      greenFirst: 'open up new perspectives ',
      greenSecond: 'of your business and yourself'
    },
    bigData: {
      title: 'Big Data Analytics',
      subtitle: 'Examination and processing of large and diverse data sets to identify information, including hidden patterns, unknown correlations, market trends and customer preferences',
      paragraphOne: 'AIS PCRS iKassa - we have developed and implemented a highly loaded information system for accepting all types of payments for goods, their fiscalization and sending them to the system of the Ministry of Taxes. AIS has an information security system certificate for working with limited distribution of individuals and legal entities',
      paragraphTwo: 'Personal accounts with the possibility of authorization by electronic digital signature. Our users have the ability to manage their program cash registers in their personal accounts, receive invoices and acts, look at BI, online receipts, work through the API with goods and other data, and have the ability to integrate through the API',
    },
    software: {
      title: 'Software Development',
      subtitle: 'The Systems Development Life Cycle - full process for planning, creating, testing, and deploying an information system',
      paragraphOne: 'iMlab Security server - receiving documents as soon as they are signed with an electronic digital signature based on encryption algorithms for information sent from user devices',
      paragraphTwo: "iKassa “Retail” - we developed software cash desks for large retail, which interact between commodity systems, cashier's workplaces and peripheral cash desk equipment with further receipt fiscalization",
    },
    systems: {
      title: 'System Integration',
      subtitle: 'Bringing together the component sub-systems into one system and ensuring that the subsystems function together as a system',
      paragraphOne: 'We developed API for all mobile applications included in the cash register system for integration with any commodity accounting systems',
      paragraphTwo: 'We developed the billing system',
    },
    mobile: {
      title: 'Mobile Apps',
      subtitle: 'Development of various software to work on smartphones, tablets and other devices running on different operating systems',
      paragraphOne: "iKassa - mobile application «iKassa» (Android, iOs) to receive the payments from users' electronic devices. Integrations of our mobile applications with 4 different bank processing have been made. All applications transmit data over secure channels TLS",
      paragraphTwo: 'Picker - system for accepting orders from users through the mobile application of a large retailer, sending these orders to the mobile application of the goods collector integrated into the barcode scanner',
    },
    pagination: {
      back: 'Back',
      forward: 'Next Slide',
      numbers: 'of'
    }
  },
  expertise: {
    title: {
      mainText: 'EXPERTISE',
      greenFirst: 'iMlab ltd ',
      whiteFirst: 'is a leading information technology and web design company in Belarus that provides',
      greenSecond: 'information communication technology (ICT) solutions, products and services',
      whiteSecond: 'using ultra-modern technologies',
    },
    grid: {
      '1': {
        title: 'High loaded systems',
        subtitle: 'Design and development',
        listHeader: 'We design reliable and efficient high loaded systems based on microservice architecture and principles of:',
        descriptions: ["That handles  large volumes of data requests and backend computations."],
        '1': 'distribution',
        '2': 'scalability',
        '3': 'multiple computations',
      },
      '2': {
        title: 'Mobile and web applications',
        subtitle: 'Full-cycle of development services\n',
        listHeader: 'We deliver full-cycle mobile development services, including:',
        '1': 'prototyping - create interactive prototypes to get \'look and feel\' of the product',
        '2': 'UI/UX design - how your product looks (UI) and how your product feels (UX)',
        '3': 'programming - our broad technological expertise help to develop and make needed integrations in the best way',
        '4': 'testing - our mobile testers are involved at every stage of the development process to help identify improvement areas',
        '5': 'support - after the release, we  support and maintain your mobile application'
      },
      '3': {
        title: 'Software Cash Register System',
        subtitle: 'Programmatic cash register',
        listHeader: 'An information automatic system Software Cash Register System was developed to',
        '1': "to collect data from client applications that perform the functions of program cash registers",
        '2': 'process a large amount of data and events at the program cash register',
        '3': 'develop a fiscal indicator and transfer data to the state information system of the Ministry of Taxes and Duties of the Republic of Belarus'
      },
      '4': {
        title: 'Trade management system',
        subtitle: 'Web-service that helps you organize your trading',
        descriptions: ["Basic automated trade management system for small business is just the beginning for being the favourite trading platform for our clients. The base functionality of the system is for the purpose of differential accounting and  to increase the sales of our customers.",
          "We have laid the statistical foundation for improving our system and achieving the goal on the profit side for our customers. Each step of the development is vital groundwork for our ultimate goal of developing an ultra-modern complete trading system."]
      },
      '5': {
        title: 'Courier SaaS solution',
        subtitle: 'Courier and delivery service orders management',
        descriptions: ["Due to stay-at-home orders, people turned to on-demand delivery apps to get everything from essential items like food and groceries to clothes and services.",
          "Our solution is simple software that streamlines your delivery business, enabling your couriers and drivers to get the customers' orders from your management system, to deliver them to the people who placed orders and fiscalize these orders. All the information about the orders gets back to your system.",
          "Courier can be used for restaurants, retail, e-commerce, food delivery, grocery delivery, home services and many industries, who deal with goods and services delivery."]
      },
      '6': {
        title: 'Order delivery picker',
        subtitle: 'Collection of orders for delivery services management',
        descriptions: [ "Correct picking ensures that a company is able to meet the demand of the customer on-time every time. The person-to-item methodology is the most commonly used methodology in warehouses today, typically it involves quite a lot of human interface and leads to a lot of mistakes in the commodity accounting. Our application helps to automate the order picking and address the inefficiencies."]
      },
    }
  },
  contact: {
    mainText: "CONTACT US",
    subtitle: 'If you have any questions, please contact us',
    formText: {
      name: "Name",
      phone: "Phone",
      email: "E-mail",
      subject: "Subject",
      message: "Your message",
      limitText: "Character limit",
      personalData: "I agree to the processing of personal data",
      button: "SEND",
      address: 'Address',
      thanks: 'Thank you!',
      responseText: 'Your message has been sent. Our managers will answer you as soon as possible!',
      addressText: '3A, Tolbuhina Str., office 106a, 220012, Minsk, Belarus',
      closeButton: 'CLOSE'
    }
  }
}
