<template>
  <div class="AboutBlockWrapper">
    <AboutTitle/>
    <AboutPicGrid/>
    <MobilePicSlider/>
<!--    <div class="ContainedButtonBorder">-->
<!--      <button @click="join" class="GreenContainedButton">Join our team</button>-->
<!--    </div>-->
  </div>
</template>

<script>
import AboutTitle from "@/components/about_us_block/AboutTitle";
import AboutPicGrid from "@/components/about_us_block/AboutPicGrid";
import "@/assets/styles/global-styles.css"
import MobilePicSlider from "@/components/about_us_block/MobilePicSlider"


export default {
  name: 'AboutUsBLock',
  components: {
    AboutTitle, AboutPicGrid,MobilePicSlider
  },
  methods:{
    join(){
      this.$router.push('/join-us')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.AboutBlockWrapper {
  min-height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #FFFFFF;
  padding-bottom: 42px;
}
.AboutBlockWrapper  .ContainedButtonBorder .GreenContainedButton {
  width: 300px;
}
@media (max-width: 1028px) {
  .AboutBlockWrapper{
    background-size: calc((100vw * 2) - 100px);
  }
}
@media (max-width: 528px) {
  .AboutBlockWrapper{
    padding-bottom: 0;
  }
  .AboutGridWrapper {
    display: none;
  }
  .MobileSliderWrapper{
    display: flex;
  }
}
</style>
